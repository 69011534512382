<template>
  <section class="video-tutorial">
    <md-button
      class="md-icon-button md-normal md-raised"
      @click="show_tutorial = true"
    >
      <md-icon>help</md-icon>
      <md-tooltip md-direction="top">{{ $translate("video_tutorial.button") }}</md-tooltip>
    </md-button>

    <md-dialog
      :md-active.sync="show_tutorial"
      @md-clicked-outside="show_tutorial = false"
      class="video-tutorial__modal"
    >
      <md-dialog-title>
        {{ $translate(`video_tutorial.${section}`) }}
      </md-dialog-title>

      <youtube :video-id="sources[section]" player-height="400"/>
      <md-dialog-actions>
        <md-button
          data-cy="done"
          class="md-raised md-primary"
          @click="show_tutorial = false"
        >{{ $translate("close") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </section>
</template>

<script>
export default {
  props: {
    section: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      show_tutorial: false,
      sources: {
        collection_banners: "mUtADo9FFwQ",
        highlighted_products: "MKVYmpPi70c",
        product_creation: "fjuPuFFMJQo",
        menu_items: "UD4FHBvOFAc",
        important_message_bar: "Tkcqkxyi0xw",
        popular_products: "gRI0Vu8YDL8",
        special_message_banner: "JrlYOw0V9gA",
        footer_content: "O2ywwuj7n7E",
        style_config: "iOxNOGp2h9w",
        story_banner: "g6OsEGBXSfI"
      }
    }
  },
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .video-tutorial {
    float: right;

    &__modal {
      iframe {
        height: 360px !important;
        width: 100%;

        @media (max-width: $mobile) {
          height: 300px !important;
        }
      }
    }
  }
</style>
