<template>
  <section class="footer-content scroll-content">
    <h3 class="md-display-1 title-with-video-guide">
      {{ $translate("footer_content.title") }}

      <video_tutorial section="footer_content" />
    </h3>

    <language_switcher
      @change-language="language => selected_language = language"
      :allow_only_enabled="true"
      :show_all_languages="false"
      :selected_language="selected_language"
      :bottom_border="true"
    />

    <div v-if="can_user_manage_content">
      <h3>{{ $translate("footer_content.section_title") }}:</h3>
      <Prism_editor v-model="updating_footer_content" language="js" class="footer-content__editor"/>
    </div>
  </section>
</template>

<script>
import "prismjs"
import { mapState, mapMutations } from "vuex"
import Prism_editor from "vue-prism-editor"
import { CONTENT_STORE, ADMIN } from "../../../constants/others_constants"
import { UPDATE_FOOTER_CONTENT } from "../../../stores/Admin/content/constants"
import "vue-prism-editor/dist/VuePrismEditor.css";
import { CONTENT_DATA_UPDATED } from "../../../stores/Admin/constants"
import language_switcher from "../language_switcher"
import default_footer_content from "../../../constants/empty_objects/default_footer_content"
import video_tutorial from "../video_tutorial"

const prismjs_styles = () => import("prismjs/themes/prism.css")

export default {
  components: {
    Prism_editor,
    language_switcher,
    video_tutorial
  },
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selected_language: ""
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, ["footer_content"]),
    updating_footer_content: {
      get() {
        return (
          typeof this.footer_content === "string" ? this.footer_content : this.footer_content[this.selected_language] || default_footer_content
        ).replace(/ {9}/g, "\n")
      },
      set(new_footer) {
        this.update_footer([new_footer, this.selected_language])
        this.content_data_updated({ types_updated: ["footer_content"] })
      }
    }
  },
  beforeCreate() {
    prismjs_styles()
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      update_footer: UPDATE_FOOTER_CONTENT
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED
    }),
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;

  .footer-content {
    &__editor {
      height: 400px;
    }
  }
</style>
