<template>
  <section class="popular-products scroll-content">
    <h3 class="md-display-1 title-with-video-guide">
      {{ $translate("popular_products.title") }}

      <video_tutorial section="popular_products" />
    </h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("popular_products.message") }}</div>
    </section_description_preview>

    <div v-if="can_user_manage_content" class="popular-products__buttons">
      <md-button
        data-cy="select-popular-products"
        class="md-raised md-primary"
        @click="open_modal = true"
      >
        {{ $translate("popular_products.select_product") }}
      </md-button>
    </div>

    <div v-if="get_popular_products.length">
      <p class="md-headline">{{ $translate("popular_products.selected_products") }}:</p>
      <draggable v-model="get_popular_products" group="get_popular_products" @start="drag=true" @end="drag=false">
        <transition-group>
          <div
            class="entity-preview md-elevation-3"
            v-for="product in get_popular_products"
            :key="product.id"
          >
            <img :src="get_correct_first_image(product)" :alt="get_title(product)">
            <p>{{ get_title(product) }}</p>
          </div>
        </transition-group>
      </draggable>
    </div>

    <entity_selection_modal
      v-if="can_user_manage_content"
      :modal_title="$translate('popular_products.select_product')"
      :open_modal="open_modal"
      :enable_collection_selection="false"
      :index_or_id_of_selected_entity="popular_products"
      @entity_selected="product_selected"
      @toggle_modal="open_modal = !open_modal"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import draggable from "vuedraggable"
import { CONTENT_STORE, ADMIN, PRODUCTS_STORE, ADMIN_STORE } from "../../../constants/others_constants"
import { UPDATE_POPULAR_PRODUCTS, UPDATE_POPULAR_PRODUCTS_POSITIONING } from "../../../stores/Admin/content/constants"
import { CONTENT_DATA_UPDATED } from "../../../stores/Admin/constants"
import entity_selection_modal from "../entity_selection_modal"
import section_description_preview from "../section_description_preview"
import get_correct_first_image from "../../../../Shared/methods/get_correct_first_image"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import video_tutorial from "../video_tutorial"

export default {
  components: {
    entity_selection_modal,
    section_description_preview,
    draggable,
    video_tutorial
  },
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      open_modal: false
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, ["popular_products"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_products",
      "shopify_products"
    ]),
    ...mapState(ADMIN_STORE, [
      "admin_language"
    ]),
    get_popular_products: {
      get() {
        return this.popular_products.map(popular_product => [
            ...(this.imported_products || []),
            ...(this.shopify_products || [])
          ].find(({ id }) => popular_product === id)
        ).filter(val => val)
      },
      set(updated_positioning) {
        this.update_product_positioning(updated_positioning.map(({ id }) => id))
        this.content_data_updated({ types_updated: ["popular_products"] })
      }
    }
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      update_popular_products: UPDATE_POPULAR_PRODUCTS,
      update_product_positioning: UPDATE_POPULAR_PRODUCTS_POSITIONING
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED
    }),
    get_correct_first_image,
    get_title(product) {
      return get_correct_translation_value(product, "title", [this.admin_language])
    },
    product_selected(id) {
      this.update_popular_products(id)
      this.content_data_updated({ types_updated: ["popular_products"] })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;

  .popular-products {
    &__buttons {
      margin: $default-size 0 $default-size;
      text-align: center;
    }
  }
</style>
