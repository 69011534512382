<template>
  <section class="about-page-config scroll-content">
    <h3 class="md-display-1">
      {{ $translate("about_page_configuration.title") }}
    </h3>

    <md-checkbox
      v-model="enable_about_page"
      class="md-primary about-page-config__checkbox"
    >
      {{ $translate("about_page_configuration.enable_about_page") }}
    </md-checkbox>

    <div v-if="can_user_manage_content">
      <hr>

      <language_switcher
        :allow_only_enabled="true"
        :show_all_languages="false"
        :selected_language="selected_language"
        :bottom_border="true"
        @change-language="change_language"
      />
    </div>

    <div v-if="show_edit_fields">
      <md-tabs class="md-transparent full-width-tabs-navigation" :md-active-tab="active_tab">
        <md-tab id="tab-banners" :md-label="$translate(`about_page_configuration.banners`)">
          <div class="about-page-config__banners">
            <div v-for="banner_type in banner_types" :key="banner_type" class="about-page-config__banner">
              <p class="md-headline">{{ $translate(`about_page_configuration.${banner_type}_image`) }}:</p>
              <transition name="special-banner-fade">
                <div v-if="banner_change_states[banner_type] && can_user_manage_content" class="image-upload">
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    @drop="event => load_image(event.target.files[0], banner_type, save_banner_image)"
                    @input="event => load_image(event.target.files[0], banner_type, save_banner_image)"
                    v-cloak
                  >
                  <label for="image-upload">
                    <md-icon>cloud_upload</md-icon>
                    {{ $translate("drag_and_drop_image") }}
                  </label>
                </div>
                <div v-else>
                  <img :src="about_page[banner_type].thumb_src" alt="Top banner">
                  <md-button
                    v-if="can_user_manage_content"
                    class="md-raised md-primary"
                    @click="banner_change_states[banner_type] = true"
                  >
                    {{ $translate("about_page_configuration.change_banner") }}
                  </md-button>
                  <md-button
                    v-if="can_user_manage_content"
                    class="md-raised md-accent"
                    @click="delete_banner(banner_type)"
                  >
                    {{ $translate("about_page_configuration.delete_banner") }}
                  </md-button>
                </div>
              </transition>
            </div>
          </div>
        </md-tab>
        <md-tab id="tab-texts" :md-label="$translate(`about_page_configuration.texts`)">
          <p class="md-headline">{{ $translate(`about_page_configuration.texts`) }}:</p>
          <div class="about-page-config__inputs">
            <div class="about-page-config__input">
              <md-field>
                <label>{{ $translate("about_page_configuration.page_title") }}:</label>
                <md-input
                  :disabled="!can_user_manage_content"
                  :value="texts.title || ''"
                  @change="event => update_text('title', event.target.value)"
                  :placeholder="$translate('about_page_configuration.title')"
                />
              </md-field>
              <md-field>
                <label>{{ $translate("about_page_configuration.page_quote") }}:</label>
                <md-input
                  :disabled="!can_user_manage_content"
                  :value="texts.quote || ''"
                  @change="event => update_text('quote', event.target.value)"
                  :placeholder="$translate('about_page_configuration.page_quote')"
                />
              </md-field>
            </div>

            <div class="about-page-config__input--textarea about-page-config__input">
              <p class="md-subheading">{{ $translate("about_page_configuration.top_paragraph") }}:</p>
              <md-field>
                <md-textarea
                  :disabled="!can_user_manage_content"
                  :value="about_page.top_paragraph[selected_language] || ''"
                  @change="event => update_top_paragraph(event.target.value)"
                />
              </md-field>
            </div>
          </div>
        </md-tab>
        <md-tab id="tab-tiles" :md-label="$translate(`about_page_configuration.tiles`)">
          <div class="about-page-config__tiles">
            <p class="md-headline">{{ $translate(`about_page_configuration.tiles`) }}:</p>
            <div
              v-for="(pair, index) in about_page.tile_pairs || []"
              :key="`tile-pair-${index}`"
              class="about-page-config__pair"
            >
              <md-content
                v-for="(tile, tile_index) in pair"
                :key="`tile-${tile_index}-${index}`"
                class="about-page-config__tile md-elevation-3"
              >
                <div v-if="tile && tile.src !== undefined">
                  <transition name="special-banner-fade">
                    <div v-if="tile && !tile.src.image_url && can_user_manage_content">
                      <div class="image-upload">
                        <input
                          type="file"
                          id="image-upload"
                          accept="image/*"
                          @drop="event => load_image(
                            event.target.files[0], 'src', update_tile,
                            index, tile_index
                          )"
                          @input="event => load_image(
                            event.target.files[0], 'src', update_tile,
                            index, tile_index
                          )"
                          v-cloak
                        >
                        <label for="image-upload">
                          <md-icon>cloud_upload</md-icon>
                          {{ $translate("drag_and_drop_image") }}
                        </label>
                      </div>
                      <md-button
                        v-if="can_user_manage_content"
                        class="md-raised md-primary"
                        @click="convert_to_text(index, tile_index, selected_language)"
                      >
                        {{ $translate("about_page_configuration.convert_to_text") }}
                      </md-button>
                    </div>
                    <div v-else>
                      <img :src="tile.src.image_url" alt="Tile image">
                      <md-button
                        v-if="can_user_manage_content"
                        class="md-raised md-primary"
                        @click="convert_to_image(index, tile_index)"
                      >
                        {{ $translate("about_page_configuration.change_banner") }}
                      </md-button>
                      <md-button
                        v-if="can_user_manage_content"
                        class="md-raised md-primary"
                        @click="convert_to_text(index, tile_index, selected_language)"
                      >
                        {{ $translate("about_page_configuration.convert_to_text") }}
                      </md-button>
                    </div>
                  </transition>
                </div>
                <div v-else>
                  <md-field>
                    <md-textarea
                      :disabled="!can_user_manage_content"
                      :value="tile ? tile[selected_language] : '' || ''"
                      @change="event => update_tile(selected_language, index, tile_index, event.target.value)"
                    />
                  </md-field>
                  <md-button
                    v-if="can_user_manage_content"
                    class="md-raised md-primary"
                    @click="convert_tile_to_image([index, tile_index])"
                  >
                    {{ $translate("about_page_configuration.convert_to_image") }}
                  </md-button>
                </div>
              </md-content>
              <md-button
                v-if="can_user_manage_content"
                class="md-raised md-accent"
                @click="delete_tile_pair(index)"
              >
                {{ $translate("about_page_configuration.delete_tiles") }}
              </md-button>
            </div>
            <md-button
              v-if="can_user_manage_content"
              class="md-primary md-raised md-icon-button md-fab"
              @click="add_about_page_tiles((about_page.tile_pairs || []).length)"
            >
              <md-icon>add</md-icon>
              <md-tooltip>{{ $translate("about_page_configuration.add_tiles") }}</md-tooltip>
            </md-button>
          </div>
        </md-tab>
        <md-tab id="tab-bottom-paragraphs" :md-label="$translate(`about_page_configuration.bottom_paragraphs`)">
          <p class="md-headline">{{ $translate(`about_page_configuration.bottom_paragraphs`) }}:</p>
          <div
            v-for="(paragraph, index) in bottom_paragraphs"
            :key="`bottom-paragraph-${index}`"
            class="about-page-config__bottom-paragraph md-elevation-3"
          >
            <md-field>
              <label>{{ $translate("about_page_configuration.paragraph_title") }}:</label>
              <md-input
                :disabled="!can_user_manage_content"
                :value="(paragraph[selected_language] && paragraph[selected_language].title) || ''"
                @change="event => update_bottom_paragraph(index, 'title', event.target.value)"
                :placeholder="$translate('about_page_configuration.paragraph_title')"
              />
            </md-field>
            <md-field v-if="paragraph[selected_language]" >
              <md-textarea
                :disabled="!can_user_manage_content"
                :value="paragraph[selected_language].message"
                @change="event => update_bottom_paragraph(index, 'message', event.target.value)"
              />
            </md-field>
            <md-button
              v-if="can_user_manage_content"
              class="md-icon-button md-raised md-accent"
              @click="delete_about_page_bottom_paragraph(index)"
            >
              <md-icon>delete</md-icon>
            </md-button>
          </div>
          <md-button
            v-if="can_user_manage_content"
            class="md-icon-button md-raised md-primary"
            @click="() => add_about_page_bottom_paragraph([bottom_paragraphs.length, selected_language])"
          >
            <md-icon>add</md-icon>
          </md-button>
        </md-tab>
      </md-tabs>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { CONTENT_STORE, ADMIN, CONFIGURATION_STORE, USER_STORE, CONTENT } from "../../../constants/others_constants"
import { CONTENT_DATA_UPDATED, UPDATE_IMAGES_FOR_DELETE, UPDATE_LOADER } from "../../../stores/Admin/constants"
import {
  UPDATE_ABOUT_PAGE_ABILITY, DELETE_ABOUT_PAGE_TILES, UPDATE_ABOUT_PAGE_TILE,
  ADD_ABOUT_PAGE_TILES, UPDATE_ABOUT_PAGE_TEXTS, UPDATE_ABOUT_PAGE_TOP_PARAGRAPH,
  ADD_ABOUT_PAGE_BOTTOM_PARAGRAPH, UPDATE_ABOUT_PAGE_BOTTOM_PARAGRAPH,
  DELETE_ABOUT_PAGE_BOTTOM_PARAGRAPH, UPDATE_ABOUT_PAGE_BANNER, DELETE_ABOUT_PAGE_BANNER,
  UPDATE_ABOUT_PAGE_LASER_USAGE, CONVERT_ABOUT_PAGE_TILE_TO_IMAGE, CONVERT_ABOUT_PAGE_TILE_TO_TEXT,
  SWITCH_ABOUT_PAGE_LANGUAGE, REMOVE_ABOUT_PAGE_LANGUAGE, TOGGLE_ABOUT_PAGE_ABILITY,
  SET_EMPTY_ABOUT_PAGE
} from "../../../stores/Admin/content/constants"
import upload_image from "../../../methods/upload_image"
import { languages } from "../../../../Shared/constants/other"
import language_switcher from "../language_switcher"
import empty_about_page from "../../../constants/empty_objects/empty_about_page"
import un_bind from "../../../../Shared/methods/un_bind"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import { event_hub } from "../../../../../main"
import { image_dimension_names } from "../../../../../data/other_constants"

export default {
  components: {
    language_switcher
  },
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show_edit_fields: true,
      first_load: true,
      uploaded_image: {},
      banner_change_states: {
        top_banner: false,
        bottom_banner: false,
      },
      banner_types: ["top_banner", "bottom_banner"],
      selected_language: "",
      active_tab: undefined,
      languages
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, ["about_page_content"]),
    ...mapState(CONFIGURATION_STORE, ["project_config", "translations"]),
    ...mapState(USER_STORE, ["user_info"]),
    does_about_page_exist() {
      return !!Object.keys(this.about_page_content).length
    },
    about_page() {
      return {
        ...un_bind(empty_about_page),
        ...this.about_page_content
      }
    },
    correct_flag_image() {
      return `/static/icons/countries/${this.selected_language}.svg?${this.selected_language}`
    },
    texts() {
      return (this.about_page.texts[this.selected_language] || {})
    },
    bottom_paragraphs() {
      return (this.about_page.bottom_paragraphs || [])
    },
    enable_about_page: {
      get() { return this.about_page.enable_about_page },
      set(value) {
        this.toggle_about_page_ability(value)
        this.content_data_updated({ types_updated: ["about_page_content"] })
      }
    }
  },
  mounted() {
    this.change_language(get_correct_translation_value(this, undefined, [this.user_info.language_code], undefined, true))

    if (!this.does_about_page_exist) this.set_empty_about_page()

    this.banner_change_states.top_banner = !(this.about_page.top_banner || {}).src
    this.banner_change_states.bottom_banner = !(this.about_page.bottom_banner || {}).src
  },
  watch: {
    about_page_content({ texts }) {
      if (!texts) {
        this.set_empty_about_page()
        this.re_render_contents()
      }
    },
    selected_language(language) {
      this.switch_new_language(language)
      this.re_render_contents()
    },
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      delete_about_page_banner: DELETE_ABOUT_PAGE_BANNER,
      update_about_page_banner: UPDATE_ABOUT_PAGE_BANNER,
      update_about_page_ability: UPDATE_ABOUT_PAGE_ABILITY,
      delete_about_page_tiles: DELETE_ABOUT_PAGE_TILES,
      update_about_page_tile: UPDATE_ABOUT_PAGE_TILE,
      add_about_page_tiles: ADD_ABOUT_PAGE_TILES,
      update_about_page_texts: UPDATE_ABOUT_PAGE_TEXTS,
      update_about_page_top_paragraph: UPDATE_ABOUT_PAGE_TOP_PARAGRAPH,
      add_about_page_bottom_paragraph: ADD_ABOUT_PAGE_BOTTOM_PARAGRAPH,
      update_about_page_bottom_paragraph: UPDATE_ABOUT_PAGE_BOTTOM_PARAGRAPH,
      delete_about_page_bottom_paragraph: DELETE_ABOUT_PAGE_BOTTOM_PARAGRAPH,
      update_about_page_laser_udage: UPDATE_ABOUT_PAGE_LASER_USAGE,
      convert_tile_to_image: CONVERT_ABOUT_PAGE_TILE_TO_IMAGE,
      convert_tile_to_text: CONVERT_ABOUT_PAGE_TILE_TO_TEXT,
      switch_language: SWITCH_ABOUT_PAGE_LANGUAGE,
      remove_language: REMOVE_ABOUT_PAGE_LANGUAGE,
      toggle_about_page_ability: TOGGLE_ABOUT_PAGE_ABILITY,
      set_empty_about_page: SET_EMPTY_ABOUT_PAGE
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED,
      update_loader: UPDATE_LOADER,
      image_delete_toggle: UPDATE_IMAGES_FOR_DELETE
    }),
    change_language(language) {
      event_hub.$emit("change_store_language", language)
      this.selected_language = language
    },
    re_render_contents() {
      this.show_edit_fields = false
      this.$nextTick(() => this.show_edit_fields = true)
    },
    convert_to_image(index, tile_index) {
      const correct_tile = this.about_page.tile_pairs[index][tile_index]

      if (correct_tile.src) this.image_delete_toggle({
        images: [correct_tile.src.image_url, correct_tile.src.thumb_url],
        store_type: CONTENT
      })

      this.convert_tile_to_image([index, tile_index])
    },
    convert_to_text(index, tile_index, selected_language) {
      const correct_tile = this.about_page.tile_pairs[index][tile_index]

      this.image_delete_toggle({
        images: [correct_tile.src.image_url, correct_tile.src.thumb_url],
        store_type: CONTENT
      })

      this.convert_tile_to_text([index, tile_index, selected_language])
    },
    load_image(value, type, call_back, ...other_parameters) {
      try {
        const reader = new FileReader()

        reader.addEventListener("load", () => {
          this.uploaded_image = {
            src: reader.result,
            file: value,
          }

          call_back(type, ...other_parameters)
        }, false)

        reader.readAsDataURL(value)
      } catch (error) {
        console.log(error)
      }
    },
    async upload_image(generate_mobile_img, generate_desktop_img) {
      return await upload_image(
        this.uploaded_image,
        `banners/about_page/${this.uploaded_image.file.name}`,
        false,
        generate_mobile_img,
        generate_desktop_img
      )
    },
    async save_banner_image(type) {
      this.update_loader(true)
      const image_sources = await this.upload_image(false, true)

      if (!image_sources) return this.update_loader(false)

      this.update_about_page_banner([
        type, image_sources[image_dimension_names.desktop], image_sources[image_dimension_names.tablet]
      ])
      this.content_data_updated({ types_updated: ["about_page_content"] })
      this.banner_change_states[type] = false
      this.update_loader(false)
    },
    delete_banner(type) {
      this.image_delete_toggle({
        images: [this.about_page[type]?.src, this.about_page[type]?.thumb_src],
        store_type: CONTENT
      })
      this.delete_about_page_banner(type)
      this.banner_change_states[type] = true
      this.content_data_updated({ types_updated: ["about_page_content"] })
    },
    update_text(key, value) {
      this.update_about_page_texts([this.selected_language, key, value])
      this.content_data_updated({ types_updated: ["about_page_content"] })
    },
    update_top_paragraph(value) {
      this.update_about_page_top_paragraph([this.selected_language, value])
      this.content_data_updated({ types_updated: ["about_page_content"] })
    },
    async update_tile(key, pair_index, tile_index, value) {
      const correct_tile = this.about_page.tile_pairs[pair_index][tile_index]

      if (correct_tile.src) this.image_delete_toggle({
        images: [correct_tile.src?.image_url, correct_tile.src?.thumb_url],
        store_type: CONTENT
      })

      if (key === "src") {
        this.update_loader(true)

        const image_sources = await this.upload_image(true, false)

        if (!image_sources) return this.update_loader(false)

        value = {
          image_url: image_sources[image_dimension_names.tablet],
          thumb_url: image_sources[image_dimension_names.mobile]
        }

        this.update_loader(false)
      }

      this.update_about_page_tile([pair_index, tile_index, key, value])
      this.content_data_updated({ types_updated: ["about_page_content"] })
    },
    delete_tile_pair(pair_index) {
      this.about_page.tile_pairs[pair_index].forEach(({ src }) => src ? this.image_delete_toggle({
        images: [src?.image_url, src?.thumb_url],
        store_type: CONTENT
      }) : null)

      this.delete_about_page_tiles(pair_index)
      this.content_data_updated({ types_updated: ["about_page_content"] })
    },
    update_bottom_paragraph(index, key, value) {
      this.update_about_page_bottom_paragraph([index, this.selected_language, key, value])
      this.content_data_updated({ types_updated: ["about_page_content"] })
    },
    switch_new_language(language) {
      if (!this.about_page.texts[language]) this.content_data_updated({ types_updated: ["about_page_content"] })

      this.switch_language(language)
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/admin_image_upload.scss";
  @use "../../../../../styles/flag_change.scss";

  .about-page-config {
    &__checkbox {
      margin: 0 0 $default-size;
    }
    &__live-preview {
      h3 {
        cursor: pointer;
      }
      .md-icon {
        margin-right: $default-size;
      }
      .about-page {
        max-width: $maximum-width;
        margin: 0 auto $double-default-size;
      }
    }

    &__banner {
      display: block;

      img {
        display: block;
        width: 100%;
        max-width: 500px;
        max-height: 300px;
        object-fit: cover;
        object-position: center;
      }

      .image-upload {
        margin: 0;
      }
    }

    &__input {
      display: inline-block;
      width: 300px;
      vertical-align: top;

      &:first-child {
        margin-right: $default-size;

        p {
          margin-bottom: 0;
        }
      }

      &--textarea {
        width: 100%;
        max-width: 500px;
      }

      &s {
        margin-top: $default-size;
      }
    }

    &__tile {
      text-align: center;

      img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
      }

      .md-field {
        margin: 0;
        padding-top: 0;
      }

      .md-button {
        margin: $default-size $half-default-size;
      }

      textarea {
        width: 100% !important;
        height: 300px !important;
        padding-bottom: 100%;
        text-align: left;
      }
      
      &s {
        max-width: $maximum-width;
      }
    }

    &__pair {
      display: grid;
      grid-column-gap: $default-size;
      grid-row-gap: $default-size;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: $default-size;
      padding-bottom: $default-size;
      border-bottom: 1px solid $material-grey;

      @media (max-width: $tablet--small) {
        grid-template-columns: 1fr;
      }
    }

    &__bottom-paragraph {
      position: relative;
      display: inline-block;
      width: 300px;
      margin: 0 $default-size $default-size 0;
      padding: $default-size;
      box-sizing: border-box;
      vertical-align: top;

      @media (max-width: $tablet--small) {
        display: block;
        width: 100%;
        margin: 0 0 $default-size;
      }

      &:last-child {
        margin-right: 0;
      }

      .md-icon-button {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .md-tabs-content {
      height: auto !important;
    }
  }

  .expand-live-preview-enter-active, .expand-live-preview-leave-active {
    transition: .5s;
  }
  .expand-live-preview-enter, .expand-live-preview-leave-to {
    opacity: 0;
    transform: translateY(-200px);
  }
</style>
