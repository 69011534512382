<template>
  <section class="story-banner-admin scroll-content">
    <h3 class="md-display-1 title-with-video-guide">
      {{ $translate("story_banner.name") }}

      <video_tutorial section="story_banner" />
    </h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("story_banner.message") }}</div>
    </section_description_preview>

    <div class="story-banner-admin__actions">
      <p class="md-subheading">{{ $translate("story_banner.images") }}:</p>
      <transition name="special-banner-fade">
        <div v-if="enable_change_image" class="image-upload">
          <input
            data-cy="story-banner-image-upload"
            type="file"
            id="image-upload"
            accept="image/*"
            @drop="event => add_image(event.target.files[0])"
            @input="event => add_image(event.target.files[0])"
            v-cloak
          >
          <label for="image-upload">
            <md-icon>cloud_upload</md-icon>
            {{ $translate("drag_and_drop_image") }}
          </label>
        </div>
      </transition>
      <transition name="special-banner-fade">
        <div v-if="!enable_change_image" class="story-banner-admin__change-buttons">
          <md-button
            data-cy="change-banner"
            class="md-raised md-primary"
            @click="enable_change_image = true"
          >
            {{ $translate("story_banner.change_banner") }}
          </md-button>
          <md-button
            data-cy="delete-banner"
            class="md-raised md-accent"
            @click="delete_banner"
          >
            {{ $translate("story_banner.delete_banner") }}
          </md-button>
        </div>
      </transition>
    </div>

    <div v-if="story_banner" class="story-banner-admin__inputs">
      <p class="md-subheading">{{ $translate("story_banner.title") }}:</p>
      <md-field>
        <md-input
          data-cy="story-banner-title"
          :disabled="!can_user_manage_content"
          :value="story_banner.title || ''"
          @change="event => update_banner_text('title', event.target.value)"
          :placeholder="$translate('story_banner.title')"
        />
      </md-field>

      <p class="md-subheading">{{ $translate("story_banner.text") }}:</p>
      <text_and_html_editor
        data-cy="story-banner-editor"
        :disabled="!can_user_manage_content"
        :value="story_banner.message || ''"
        @input="value => update_banner_text('message', value)"
      />

      <p class="md-subheading">{{ $translate("story_banner.buttons") }}:</p>

      <div
        v-for="({ name }, ind) in buttons"
        :key="`story-banner-admin-${name}-${ind}`"
        class="story-banner-admin__button-wrapper md-elevation-3"
      >
        <div class="story-banner-admin__button-input">
          <md-field>
            <label>{{ $translate('name') }}</label>
            <md-input
              :disabled="!can_user_manage_content"
              :value="name"
              @change="event => update_button(ind, 'name', event.target.value)"
            />
          </md-field>
        </div>
        <md-button
          v-if="can_user_manage_content"
          class="md-icon-button md-raised md-accent"
          @click="delete_button(ind)"
        >
          <md-icon>delete</md-icon>
          <md-tooltip md-direction="top">{{ $translate("delete") }} {{ $translate("button") }}</md-tooltip>
        </md-button>
      </div>
      <md-button
        data-cy="select-button-links"
        v-if="can_user_manage_content"
        class="md-raised md-primary md-icon-button"
        @click="open_modal = true"
      >
        <md-icon>add</md-icon>
        <md-tooltip md-direction="left">{{ $translate("story_banner.select_links") }}</md-tooltip>
      </md-button>

      <entity_selection_modal
        v-if="can_user_manage_content"
        :modal_title="$translate('story_banner.add_link_to_entity')"
        :open_modal="open_modal"
        @entity_selected="select_entity"
        @toggle_modal="open_modal = !open_modal"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import url_enity_encoder from "urlencode"
import { CONTENT_STORE, ADMIN, CONFIGURATION_STORE, CONTENT } from "../../../constants/others_constants"
import { CONTENT_DATA_UPDATED, UPDATE_IMAGES_FOR_DELETE, UPDATE_LOADER, USER_EDITED_SPECIAL_HOMEPAGES } from "../../../stores/Admin/constants"
import {
  DELETE_STORY_BANNER, UPDATE_STORY_BANNER_IMAGES, UPDATE_STORY_BANNER_BUTTONS,
  UPDATE_STORY_BANNER_MESSAGE, UPDATE_STORY_BANNER_TITLE
} from "../../../stores/Admin/content/constants"
import entity_selection_modal from "../entity_selection_modal"
import text_and_html_editor from "../../utils/text_and_html_editor"
import section_description_preview from "../section_description_preview"
import { collection, product } from "../../../../Shared/constants/other"
import create_correct_product_url from "../../../../Client/methods/create_correct_product_url"
import get_curly_brace_content from "../../../../Shared/methods/get_curly_brace_content"
import upload_image from "../../../methods/upload_image"
import { UPDATE_PAGE_TYPE_TRANSLATION } from "../../../stores/Admin/configuration/constants"
import { image_dimension_names, page_types } from "../../../../../data/other_constants"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import video_tutorial from "../video_tutorial"

export default {
  components: {
    section_description_preview,
    entity_selection_modal,
    text_and_html_editor,
    video_tutorial
  },
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      enable_change_image: false,
      open_modal: false,
      first_load: true,
      uploaded_image: {}
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, ["banners"]),
    ...mapState(CONFIGURATION_STORE, ["project_config"]),
    story_banner() {
      return this.banners.story_banner
    },
    buttons() {
      return this.story_banner?.buttons || []
    }
  },
  mounted() {
    this.enable_change_image = !this.story_banner
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      update_story_banner_images: UPDATE_STORY_BANNER_IMAGES,
      update_story_banner_message: UPDATE_STORY_BANNER_MESSAGE,
      update_story_banner_title: UPDATE_STORY_BANNER_TITLE,
      update_story_banner_buttons: UPDATE_STORY_BANNER_BUTTONS,
      delete_story_banner: DELETE_STORY_BANNER
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED,
      update_loader: UPDATE_LOADER,
      user_edited_special_homepages: USER_EDITED_SPECIAL_HOMEPAGES,
      image_delete_toggle: UPDATE_IMAGES_FOR_DELETE
    }),
    ...mapMutations(CONFIGURATION_STORE, {
      update_page_type: UPDATE_PAGE_TYPE_TRANSLATION
    }),
    /**
     * 
     */
    add_image(value) {
      try {
        const reader = new FileReader()

        reader.addEventListener("load", () => {
          this.uploaded_image = {
            src: reader.result,
            file: value,
          }
          this.save_image()
        }, false)

        reader.readAsDataURL(value)
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 
     */
    async save_image() {
      this.update_loader(true)

      this.image_delete_toggle({
        images: [this.story_banner?.src, this.story_banner?.mobile_image],
        store_type: CONTENT
      })

      const image_sources = await upload_image(
        this.uploaded_image,
        `banners/story/${this.uploaded_image.file.name}`,
        false,
        false
      )

      if (!image_sources) return this.update_loader(false)

      this.update_story_banner_images([
        image_sources[image_dimension_names.desktop],
        image_sources[image_dimension_names.tablet]
      ])
      this.content_data_updated({ types_updated: ["banners"] })
      this.enable_change_image = false
      this.update_loader(false)
    },
    /**
     * 
     */
    update_banner_text(type, value) {
      this[`update_story_banner_${type}`](value)
      this.content_data_updated({ types_updated: ["banners"] })
    },
    delete_banner() {
      this.image_delete_toggle({
        images: [this.story_banner?.src, this.story_banner?.mobile_image],
        store_type: CONTENT
      })
      this.delete_story_banner()
      this.content_data_updated({ types_updated: ["banners"] })
      this.enable_change_image = true
    },
    select_entity(entity_collection, entity_product, direct_to_product_or_collection, selecting_collection, selecting_parent_collection) {
      if (selecting_parent_collection && direct_to_product_or_collection) return

      const parent_collection = get_curly_brace_content(get_correct_translation_value(entity_collection, "body_html"))
      const direct_route = selecting_parent_collection ?
        "" : `/?${selecting_collection ? collection : product}=${
          selecting_collection ? entity_collection.handle : (entity_product.buffer_id || entity_product.id)
        }`
      const name = selecting_parent_collection ?
        url_enity_encoder.decode(parent_collection?.[1]) :
        get_correct_translation_value(selecting_collection ? entity_collection : entity_product, "title")
      const route_to_parent = `/Products/${parent_collection?.[1]}`
      let final_route

      if (direct_to_product_or_collection) final_route = direct_route
      else final_route = selecting_parent_collection ?
          route_to_parent : selecting_collection ?
            `/Products${parent_collection ? `/${parent_collection?.[1]}` : ""}/${entity_collection.handle}` :
            create_correct_product_url({ ...entity_product, collections: entity_collection && [entity_collection] })

      this.update_story_banner_buttons([
        ...(this.story_banner.buttons || []),
        { route: final_route, name }
      ])
      this.content_data_updated({ types_updated: ["banners"] })

      if (direct_to_product_or_collection) {
        this.update_page_type([
          entity_product ? page_types.single_product_homepages : page_types.couple_products_homepages,
          entity_product ? (entity_product.buffer_id || entity_product.id) : entity_collection.handle,
        ])
        this.user_edited_special_homepages(true)
      }

      this.open_modal = false
    },
    update_button(index, key, val) {
      this.update_story_banner_buttons(this.story_banner.buttons.map((button, ind) => {
        if (ind === index) button[key] = val

        return button
      }))
      this.content_data_updated({ types_updated: ["banners"] })
    },
    delete_button(index) {
      this.update_story_banner_buttons(this.story_banner.buttons.filter((_, ind) => ind !== index))
      this.content_data_updated({ types_updated: ["banners"] })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/admin_image_upload" as *;

  .story-banner-admin {
    &__actions {
      position: relative;
    }

    &__change-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $default-size;
      max-width: 600px;

      @media (max-width: $mobile--large) {
        grid-template-columns: 1fr;
        grid-gap: $half-default-size;
      }

      .md-button {
        width: 100%;
        margin: 0;
        text-align: center;
      }
    }

    &__inputs {
      .md-field {
        display: inline-block;
        width: auto;
        min-width: 300px;
      }

      .md-button {
        vertical-align: middle;
        margin-left: $default-size;
      }
    }

    &__buttons {
      margin: $double-default-size auto $default-size;
      text-align: center;
    }

    &__button {
      &-wrapper {
        display: inline-block;
        width: calc(50% - #{$default-size});
        padding: $half-default-size;
        margin: 0 $half-default-size $half-default-size;
        border-radius: $border-radius;
        overflow: hidden;
        vertical-align: middle;

        @media (max-width: $mobile) {
          display: block;
          width: calc(100% - #{$default-size});
          margin: 0 $half-default-size $half-default-size;
        }
      }

      &-input {
        display: inline-block;
        width: calc(100% - 70px);
        vertical-align: middle;

        .md-field {
          min-width: auto;
        }
      }
    }

    .story-banner {
      max-width: 1080px;
      margin: 0 auto !important;
    }

    .md-subheading {
      margin: $default-size 0 $half-default-size;
    }
  }

  .special-banner-fade-enter-active, .special-banner-fade-leave-active {
    transform: translateY(0);
    transition: opacity .5s ease-out, transform .5s ease-out;
  }
  .special-banner-fade-enter, .special-banner-fade-leave-to {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-20px);
    opacity: 0;
  }
</style>
